﻿/*
--------------------------------------------------------------------------------
This module is controller of youtube videos
--------------------------------------------------------------------------------
*/

import {OneTrustGroup} from "Integrations/OneTrust/Scripts/OneTrustGroup";

import "../Styles/youtube-player.css";

(function () {
    "use strict";
    var self = null,
        interval = null,
        SIZE_3_INDEX = 2,

        // Settings of module TODO: was published to global scope, so hopefully correct now; why options weren't used?
        settings = {
            //Selectors
            selectors: {
                VIDEO_HOLDER: "#youtube-player-container #[id]",
                BUTTONS_OPEN_VIDEO: "a.js-ytplayer",
                //This only part of selector. It's used to prevent double initialization of some node
                NOT_INITED: ":not([data-youtube-inited])",
                YTP_WRAPPER: "w-ytp-controls",
                YT_CONTROLS: "ytp-controls",
                YT_LEFT_CONTROLS: "ytp-left-controls",
                YT_RIGHT_CONTROLS: "ytp-right-controls",
                YT_BUTTON: "ytp-button",
                CONTROLS_BAR: "ytp-controls-bar",
                PROGRESS_BAR: "ytp-progress-bar",
                PLAY_PROGRESS: "ytp-play-progress",
                PLAY_ELIPS: "ytp-play-elips",
                PLAY_BUTTON: "ytp-play-button",
                VOLUME_CONTROL: "ytp-volume-control",
                VOLUME_BUTTON: "ytp-volume-button",
                VOLUME_SLIDER: "ytp-volume-slider",
                VOLUME_HANDLER: "ytp-volume-slider-handle",
                VOLUME_PANEL: "ytp-volume-panel",
                CURRENT_TIME: "ytp-time-current",
                DURATION_TIME: "ytp-time-duration",
                TIME_DIVIDER: "ytp-time-divider",
                TIME_DISPLAY: "ytp-time-display",
                QUALITY_ITEMS: "quality-items",
                SETTING_BUTTON: "ytp-settings-button",
                SETTINGS_MENU: "ytp-settings-menu",
                SETTINGS_MENU_ITEMS: "ytp-menuitems",
                FULL_SCREEN_BUTTON: "ytp-fullscreen-button",
                EXIT_FULLSCREEN_BUTTON: "ytp-exitfullscreen-button",
                active: "active",
                // icons class
                PLAY_ICON: "v-icon-play-filled",
                PAUSE_ICON: "v-icon-pause",
                VOLUME_ICON: "v-icon-volume",
                MUTE_ICON: "v-icon-mute",
                MAXIM_ICON: "v-icon-enlarge",
                MINI_ICON: "v-icon-reduce",
                SETTING_ICON: "v-icon-settings-filled",
                W_VIDEO_CONTAINER: ".w-video-container",
                BANNER_SLIDE: ".banner-slide",
                BANNER_SLIDE_IMG: ".image-ratio"
            },
            attrs: {
                VIDEO_ID: "data-videoid",
                INITED: "data-youtube-inited"
            },
            //Video settings
            video: {
                RATIO: 0.5628
            },
            //Everything about layout
            html: {
                YOUTUBE_WRAPPER: '<div class="video" id="[id]"></div>'
            },
            //Youtube settings
            youtube: {
                API_URL: "https://www.youtube.com/iframe_api"
            },
            //All other settings
            other: {
                DEFAULT_VIDEO_CONTAINER_ID: "youtube-player",//youtubeID = 'youtube-player'
                EVENT_CLICK: "click.ytplayer",
                AUTO_PLAY_HASH_MARK: "play-video",
                SHOW_VIDEO_CLASS: "show-video"
            }
        };

    ori.addModule("ytplayer", {
        //Controller of youtube functionality
        youtube: {
            init: function () {
                //Attach API
                self.youtube.api.attach();
                //Define basic events of API
                self.youtube.events.attach();
            },
            api: {
                ready: false,
                /**
                 * Attach API of youtube player. As result class [YT] will be available for creating instances of player
                 */
                attach: (function () {
                    //This variable is in close. It's flag to prevent double attaching of API
                    var attached = false;
                    return function () {
                        if (ori.oneTrust && !ori.oneTrust.isGroupAllowed(OneTrustGroup.Targeting)) {
                            return;
                        }
                        var script = null,
                            head = document.head || document.getElementsByTagName("head")[0];
                        if (attached === false) {
                            attached = true;
                            script = document.createElement("script");
                            script.type = "application/javascript";
                            script.src = settings.youtube.API_URL;
                            head.appendChild(script);
                        }
                    };
                }()),
                isReady: function () {
                    return self.youtube.api.ready;
                },
                done: function () {
                    self.youtube.api.ready = true;
                    self.youtube.waiting.checkTasks();
                }
            },
            //Storage for handles, which are called before player was inited.
            waiting: {
                handles: [],
                //Add new handle in storage
                addTask: function (handle) {
                    if (typeof handle === "function") {
                        self.youtube.waiting.handles.push(handle);
                    }
                },
                //Run all storaged handles after player is ready
                checkTasks: function () {
                    self.youtube.waiting.handles.forEach(function (handle) {
                        handle();
                    });
                    self.youtube.waiting.handles = [];
                }
            },
            events: {
                attach: (function () {
                    //This variable is in close. It's flag to prevent double attaching of API
                    var attached = false;
                    return function () {
                        if (attached === false) {
                            attached = true;
                            /**
                             * Method [onYouTubeIframeAPIReady] is global and this method is part of youtube's API
                             * Via this method we can define actions, which will be done, when API will be ready.
                             */
                            window.onYouTubeIframeAPIReady = self.youtube.events.onYouTubeIframeAPIReady;
                        }
                    };
                }()),
                //Our actions, when API is ready
                onYouTubeIframeAPIReady: function () {
                    //Set flag, that youtube API is ready and can be used
                    self.youtube.api.done();
                }
            },
            clearYoutubeInterval: function () {
                clearInterval(interval);
            }
        },
        //Player controller. Player CLASS
        player: {
            get: function (placeholder, id) {
                //Constructor of player
                var Instance = function (placeholder, id) {
                    if (YT) {
                        this.placeholder = placeholder;
                        this.id = id;
                    } else {
                        self.info("[ori.video.youtube]:: Youtube API isn't loaded yet.");
                        throw "Youtube API is not avaliable";
                    }
                };
                //Class of player
                Instance.prototype = {
                    id: null,
                    origin: null,
                    url: null,
                    placeholder: null,
                    width: null,
                    height: null,
                    player: null,
                    //Player event:: player is initialized and ready
                    onReady: function (event) {
                        //Start video as far as player is ready
                        this.player.playVideo();
                        self.ytControlsBar.events.onNewPlayer(this.player);
                        //GTM event
                        self.gtm.events.onNewPlayer(this.player);

                    },
                    //Player event:: stage of player is changed (from play to pause; from pause to play; on change seek and etc.
                    onStateChange: function (event) {
                        $(this.player.getIframe()).removeClass("ios-wrapper");
                        var playerState = this.player.getPlayerState();
                        if (playerState === 1) {
                            $("." + this.player.f.id + " ." + settings.selectors.PLAY_BUTTON).removeClass(settings.selectors.PLAY_ICON).addClass(settings.selectors.PAUSE_ICON);
                        } else if (playerState === 2) {
                            $("." + this.player.f.id + " ." + settings.selectors.PLAY_BUTTON).removeClass(settings.selectors.PAUSE_ICON).addClass(settings.selectors.PLAY_ICON);
                        }
                        self.ytControlsBar.events.onPlayerStateChange(event, this.player);
                        //GTM event
                        self.gtm.events.onPlayerStateChange(event, this.player);
                    },
                    //Get actual size according size of player's container
                    updateSize: function () {
                        this.width = $(this.placeholder).width();
                        this.height = this.width * settings.video.RATIO;
                    },
                    //Exctract an origin ID of video. This is ID from URL of video. It can be not same as video's ID in this application
                    updateOriginID: function () {
                        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/,
                            match = this.url.match(regExp);

                        if (match && match.length > 2 && match[2].length === 11) {
                            this.origin = match[2];
                        } else {
                            self.error("[ori.video.youtube]:: Cannot extract original ID of youtube video from video URL.");
                        }
                    },
                    //Exctract a URL of video
                    updateURL: function () {
                        this.url = $(this.placeholder).attr("href");
                    },

                    //Initialize player
                    init: function () {
                        var self = this;
                        //Extract URL of video
                        this.updateURL();
                        //Extract original ID of video (it can be not save with "our" ID of video)
                        this.updateOriginID();
                        //Setup size of video
                        this.updateSize();
                        //Create player
                        this.player = new YT.Player(this.id, {
                            width: this.width,
                            height: this.height,
                            videoId: this.origin,
                            playerVars: {
                                rel: 0,
                                controls: 0,
                                fs: 0
                            }, //disable related videos
                            events: {
                                "onReady": function (event) { self.onReady.call(self, event); },
                                "onStateChange": function (event) { self.onStateChange.call(self, event); }
                            }
                        });
                    }
                };
                return new Instance(placeholder, id);
            }
        },

        /**
         * GTM controller
         * This is controller manage static information. It isn't about functionality of player - it's only static
         * information about usage of player and activities of user
         */
        gtm: {
            //Events of GTM module
            events: {
                onNewPlayer: function (player) {
                    if (typeof ori.dataLayer !== "undefined") {
                        ori.dataLayer.push({ "event": "ytAPI_Ready", "ytPlayerObject": player });
                    }
                },
                onPlayerStateChange: function (event, player) {
                    var watchVideoId = null;
                    var match = event["target"].getVideoUrl().match(/v=([^&]+)/);
                    var eventData = null;
                    if (!event.data) {
                        player.stopVideo();
                        $("." + player.f.id + " ." + settings.selectors.PLAY_BUTTON)
                            .removeClass(settings.selectors.PAUSE_ICON)
                            .addClass(settings.selectors.PLAY_ICON);
                    }
                    //temporary push for next possible testing on System specialists request
                    if (typeof ori.dataLayer !== "undefined") {
                        ori.dataLayer.push({ "event": "ytOnPlayerStateChange", "ytPlayerObject": player });
                    }

                    watchVideoId = (match && match.length > 0) ? match[1] : "";
                    eventData = event["data"];

                    if (!eventData) {
                        return;
                    }

                    eventData === YT.PlayerState.PLAYING && setTimeout(self.gtm.events.onPercent, 1000, event["target"]);

                    if (eventData === YT.PlayerState.PLAYING) {
                        setTimeout(self.gtm.events.onPercent, 1000, event["target"], eventData);
                    }
                    if (typeof ori.dataLayer !== "undefined") {
                        if (eventData === YT.PlayerState.PLAYING && YT.gtmLastAction === "p") {
                            ori.dataLayer.push({
                                event: "youtube",
                                eventAction: "play",
                                eventLabel: watchVideoId
                            });
                            YT.gtmLastAction = "";
                        }
                        else if (eventData === YT.PlayerState.PAUSED) {
                            ori.dataLayer.push({
                                event: "youtube",
                                eventAction: "pause",
                                eventLabel: watchVideoId
                            });
                            YT.gtmLastAction = "p";
                        }
                        else if (eventData === YT.PlayerState.ENDED) {
                            ori.dataLayer.push({
                                event: "youtube",
                                eventAction: "end",
                                eventLabel: watchVideoId
                            });
                            YT.gtmLastAction = "p";
                        }
                    }
                },
                onPercent: function (event) {
                    var watchVideoId = event.getVideoUrl().match(/v=([^&]+)/)[1];
                    if (event["getPlayerState"]() === YT.PlayerState.PLAYING) {
                        var t = event["getDuration"]() - event["getCurrentTime"]() <= 1.5 ? 1 : (Math.floor(event["getCurrentTime"]() / event["getDuration"]() * 4) / 4).toFixed(2);
                        if (!event["lastP"] || t > event["lastP"]) {
                            event["lastP"] = t;
                            dataLayer.push({
                                event: "youtube",
                                eventAction: t * 100 + "%",
                                eventLabel: watchVideoId
                            });
                        }
                        event["lastP"] != 1 && setTimeout(self.gtm.events.onPercent, 1000, event);
                    }
                }
            }
        },


        //Custom controls bar
        ytControlsBar: {

            events: {
                onNewPlayer: function (player) {
                    //Creating all blocks for controls bar
                    var controlsBar = $("<div>", { class: settings.selectors.CONTROLS_BAR + " " + player.f.id }),
                        controlsWrap = $("<div>", { class: settings.selectors.YTP_WRAPPER }).appendTo(controlsBar),
                        controls = $("<div>", { class: settings.selectors.YT_CONTROLS }).appendTo(controlsWrap),
                        progressBar = $("<div>", { class: settings.selectors.PROGRESS_BAR }).appendTo(controls),
                        playProgress = $("<div>", { class: settings.selectors.PLAY_PROGRESS }).appendTo(progressBar),
                        // Left side controls: play, volume, time
                        leftControls = $("<div>", { class: settings.selectors.YT_LEFT_CONTROLS }).appendTo(controls),
                        playElips = $("<div>", { class: settings.selectors.PLAY_ELIPS }).appendTo(playProgress),
                        playButton = $("<button>", {
                            class: settings.selectors.PLAY_BUTTON + " "
                                + settings.selectors.YT_BUTTON + " "
                                + settings.selectors.PLAY_ICON
                        }).appendTo(leftControls),
                        volumeControl = $("<div>", { class: settings.selectors.VOLUME_CONTROL }).appendTo(leftControls),
                        volumeButton = $("<button>", {
                            class: settings.selectors.VOLUME_BUTTON + " "
                                + settings.selectors.YT_BUTTON + " "
                                + settings.selectors.VOLUME_ICON
                        }).appendTo(volumeControl),
                        volumePanel = $("<div>", { class: settings.selectors.VOLUME_PANEL }).appendTo(volumeControl),
                        volumeSlider = $("<div>", { class: settings.selectors.VOLUME_SLIDER }).appendTo(volumePanel),
                        volumeHandler = $("<div>", { class: settings.selectors.VOLUME_HANDLER }).appendTo(volumeSlider),
                        timeDisplay = $("<span>", { class: settings.selectors.TIME_DISPLAY }).appendTo(leftControls),
                        currentTime = $("<span class=" + settings.selectors.CURRENT_TIME + "> 0:00 </span>").appendTo(timeDisplay),
                        timeDivider = $("<span/>", { class: settings.selectors.TIME_DIVIDER }).html("/").appendTo(timeDisplay),
                        timeDuration = $("<span>", { class: settings.selectors.DURATION_TIME }).appendTo(timeDisplay),

                        //Right side controls: settings,
                        rightContrlos = $("<div>", { class: settings.selectors.YT_RIGHT_CONTROLS }).appendTo(controls),
                        fullScreenButton = $("<button>", {
                            class: settings.selectors.FULL_SCREEN_BUTTON + " "
                                + settings.selectors.MAXIM_ICON
                        }).appendTo(rightContrlos),
                        exitFullScreenButton = $("<button>", {
                            class: settings.selectors.EXIT_FULLSCREEN_BUTTON + " "
                                + settings.selectors.MINI_ICON
                        }).appendTo(rightContrlos),
                        settingsButton = $("<button>", {
                            class: settings.selectors.YT_BUTTON + " "
                                 + settings.selectors.SETTING_BUTTON + " "
                                 + settings.selectors.SETTING_ICON
                        }).appendTo(rightContrlos),
                        settingsMenu = $("<div>", { class: settings.selectors.SETTINGS_MENU }).appendTo(rightContrlos),
                        qualityMenu = $("<ul>", { class: settings.selectors.SETTINGS_MENU_ITEMS }).appendTo(settingsMenu),
                        qualityItem1 = $('<li class="quality-items" data-quality="hd1080">HD 1080</li>').appendTo(qualityMenu),
                        qualityItem2 = $('<li class="quality-items" data-quality="hd720">HD 720</li>').appendTo(qualityMenu),
                        qualityItem3 = $('<li class="quality-items active" data-quality="large">480</li>').appendTo(qualityMenu),
                        qualityItem4 = $(' <li class="quality-items" data-quality="medium">360</li>').appendTo(qualityMenu),
                        qualityItem5 = $('<li class="quality-items" data-quality="small">240</li>').appendTo(qualityMenu),
                        qualityItem6 = $('<li class="quality-items" data-quality="tiny">140</li>').appendTo(qualityMenu),
                        currentPlayerID = "." + player.f.id;

                    $(player.f).after(controlsBar);

                    // Show control panel after init
                    $(controlsBar).show();

                    // Youtube progress bar
                    var timeDrag = false; /* check for drag event */
                    $(progressBar).on(self.eventString("mousedown"), function (e) {
                        timeDrag = true;
                        updatebar(e.pageX);
                    });

                    //self.eventString('mousemove') - doesn't work properly
                    $(progressBar).on("mousemove", function (e) {
                        if (timeDrag) {
                            timeDrag = true;
                            updatebar(e.pageX);
                        }
                    });

                    $(progressBar).on(self.eventString("mouseup"), function (e) {
                        if (timeDrag) {
                            timeDrag = false;
                            updatebar(e.pageX);
                        }
                    });

                    function updatebar(x) {
                        var progress = $(progressBar);
                        // When a click occurs on the progress bar, seek to the
                        // appropriate moment of the video.
                        var ratio = (x - progress.offset().left) / progress.outerWidth();
                        player.seekTo(Math.round(player.getDuration() * ratio), true);
                        return false;
                    }


                    // Volume draggble bar
                    var volumeDrag = false; /* check for drag event */
                    $(volumeSlider).on(self.eventString("mousedown"), function (e) {
                        volumeDrag = true;
                        updateVolume(e.pageX);
                    });

                    //self.eventString('mousemove') - doesn't work properly
                    $(volumeSlider).on("mousemove", function (e) {
                        if (volumeDrag) {
                            volumeDrag = true;
                            updateVolume(e.pageX);
                        }
                    });

                    $(volumeSlider).on(self.eventString("mouseup"), function (e) {
                        if (volumeDrag) {
                            volumeDrag = false;
                            updateVolume(e.pageX);
                        }
                    });

                    function updateVolume(x) {
                        var volume = $(volumeSlider);
                        var ratio = (((x - volume.offset().left) / volume.outerWidth()) * 100);

                        player.setVolume((ratio).toFixed(0));
                        $(volumeHandler).css({
                            'left': ratio + "%"
                        });
                        if (ratio < 3) {
                            $(volumeButton)
                                .removeClass(settings.selectors.VOLUME_ICON)
                                .addClass(settings.selectors.MUTE_ICON);
                        } else {
                            $(volumeButton)
                                .removeClass(settings.selectors.MUTE_ICON)
                                .addClass(settings.selectors.VOLUME_ICON);
                        }
                        return false;
                    }
                    var currentVolume = player.getVolume();
                    $(volumeHandler).css({
                        'left': currentVolume + "%"
                    });

                    //Volumecontroll
                    $(volumeButton).on(self.eventString("click"), function () {
                        if (!player.isMuted()) {
                            player.mute();
                            $(this).removeClass(settings.selectors.VOLUME_ICON).addClass(settings.selectors.MUTE_ICON);
                            $(volumeHandler).css({
                                'left': "0"
                            });
                        } else {
                            player.unMute();
                            $(this).removeClass(settings.selectors.MUTE_ICON).addClass(settings.selectors.VOLUME_ICON);
                            $(volumeHandler).css({
                                'left': "50%"
                            });
                        }
                    });

                    //Current time of video
                    var durationMinute = Math.floor(player.getDuration() / 60);
                    var durationSecond = Math.floor(player.getDuration() - durationMinute * 60);
                    durationSecond = durationSecond < 10 ? "0" + durationSecond : durationSecond;
                    var duration = durationMinute + ":" + durationSecond;
                    $(timeDuration).text(duration);

                    //Quallity settings
                    $(settingsButton).on(self.eventString("click"), function () {
                        $(settingsMenu).toggleClass("visible");
                    });

                    //Quallity settings items
                    $("." + settings.selectors.QUALITY_ITEMS).on(self.eventString("click"), function () {
                        var $this = $(this);
                        var quality = $this.attr("data-quality");
                        if (quality) {
                            player.stopVideo();
                            player.setPlaybackQuality(quality);
                            $("li").removeClass(settings.selectors.active);
                            $this.addClass(settings.selectors.active);
                            $(settingsMenu).removeClass("visible");
                        }
                        player.playVideo();
                    });

                    // Fullscreen
                    $(fullScreenButton).on(self.eventString("click"), function () {
                        var videoContainer = document.getElementById(player.f.id).parentNode;
                        if(videoContainer.requestFullscreen) {
                            videoContainer.requestFullscreen();
                        } else if(videoContainer.mozRequestFullScreen) {
                            videoContainer.mozRequestFullScreen();
                        } else if(videoContainer.webkitRequestFullscreen) {
                            videoContainer.webkitRequestFullscreen();
                        } else if(videoContainer.msRequestFullscreen) {
                            videoContainer.msRequestFullscreen();
                        }
                        $(this).hide();
                        $(exitFullScreenButton).show();
                    });

                    // Exit fullscreen
                    $(exitFullScreenButton).on(self.eventString("click"), function () {
                        if (document.exitFullscreen) {
                            document.exitFullscreen();
                        } else if (document.mozCancelFullScreen) {
                            document.mozCancelFullScreen();
                        } else if (document.webkitExitFullscreen) {
                            document.webkitExitFullscreen();
                        } else if (msExitFullscreen) {
                            document.msExitFullscreen();
                        }
                        $(this).hide();
                        $(fullScreenButton).show();
                    });

                    //Play/pause button
                    $(currentPlayerID + " ." + settings.selectors.PLAY_BUTTON).on(self.eventString("click"), function (e) {
                        var $this = $(this);
                        if (player.getPlayerState() == 1) {
                            player.pauseVideo();
                            $this.removeClass(settings.selectors.PAUSE_ICON).addClass(settings.selectors.PLAY_ICON);
                        } else {
                            player.playVideo();
                            $this.removeClass(settings.selectors.PLAY_ICON).addClass(settings.selectors.PAUSE_ICON);
                        }
                    });
                },

                onPlayerStateChange: function (event, player) {
                    var currentPlayerID = "." + player.f.id;
                    // Checking current state of player
                    if (player.getPlayerState() == 1) {
                        $(currentPlayerID + " ." + settings.selectors.PLAY_BUTTON)
                            .removeClass(settings.selectors.PLAY_ICON)
                            .addClass(settings.selectors.PAUSE_ICON);

                        // Displaying time and current position of playing progress
                        if ($("." + settings.selectors.CONTROLS_BAR).length > 0) {
                            interval = setInterval(function () {
                                var progressWidth = (player.getCurrentTime() / player.getDuration()).toFixed(3) * 100;
                                $(currentPlayerID + " ." + settings.selectors.PLAY_PROGRESS).width(progressWidth + "%");
                                $(currentPlayerID + " ." + settings.selectors.PLAY_ELIPS).css({
                                    'margin-left': progressWidth + "%"
                                });

                                var currentMinute = Math.floor(player.getCurrentTime() / 60);
                                var currentSecond = Math.floor(player.getCurrentTime() - currentMinute * 60);
                                currentSecond = currentSecond < 10 ? "0" + currentSecond : currentSecond;
                                var currentTimeLabel = currentMinute + ":" + currentSecond;

                                $(currentPlayerID + " ." + settings.selectors.CURRENT_TIME).text(currentTimeLabel);
                            }, 1000);
                        }
                    }

                }
            }
        },

        //Init
        init: function (scope = null) {
            //We get only not inited buttons
            var placeholders = scope ? $(scope).find(settings.selectors.BUTTONS_OPEN_VIDEO + settings.selectors.NOT_INITED) : $(settings.selectors.BUTTONS_OPEN_VIDEO + settings.selectors.NOT_INITED);

            function initVideo(placeholder, id, modifyInstance) {
                function handleWrapper() {
                    var instance = null;
                    //Create instance of player
                    instance = self.player.get(placeholder, id);
                    if (typeof modifyInstance === "function") {
                        modifyInstance(instance);
                    }
                    //Start initialization of player
                    instance.init();
                };

                //Sometime (for example if we have [autoplay] mode can be situation
                //when player is called, but sources aren't loaded yet (youtube).
                //That's why we use wrapper for launch handle and if player isn't
                //ready yet, we storage handle and wait for player.
                if (self.youtube.api.isReady() !== false) {
                    handleWrapper();
                } else {
                    self.youtube.waiting.addTask(handleWrapper);
                }
            }

            if (placeholders.length > 0) {
                //Attach youtube API
                self.youtube.init();

                //Init each button
                $.each(placeholders, function (i, v) {
                    var placeholder = $(v),
                        id = placeholder.attr(settings.attrs.VIDEO_ID) + i,
                        $videoId = $("#" + id),
                        wrapper = null,
                        isTwinBanner = false,
                        holder = null;

                    if (placeholder.length > 0 && id !== null && id !== "") {
                        //Create wrapper
                        wrapper = $(settings.html.YOUTUBE_WRAPPER.replace("[id]", id));

                        //Get video-holder
                        holder = $(settings.selectors.VIDEO_HOLDER.replace("[id]", id));
                        holder = holder.length === 1 ? $(holder).wrap(wrapper) : null;

                        if (!$videoId.length)
                            placeholder.wrap(wrapper);

                        isTwinBanner = placeholder.closest(settings.selectors.BANNER_SLIDE).length;

                        if (isTwinBanner) {
                            var $twinBanner = placeholder.closest(settings.selectors.BANNER_SLIDE);
                            var $videoImage = $twinBanner.find(settings.selectors.BANNER_SLIDE_IMG);

                            $videoImage.on(self.eventString("click"), function () {
                                $twinBanner.addClass(settings.other.SHOW_VIDEO_CLASS);
                            });
                        }

                        /*
                        Attach event "click" in close scope, just to optimize resources and exclude other local variable from
                        scope of function
                        */
                        (function (placeholder, id) {
                            placeholder.on(settings.other.EVENT_CLICK, function (event) {
                                //Prevent all other and defaults events
                                event.preventDefault();
                                initVideo(placeholder, id);
                            });
                        }(placeholder, id));

                    }
                    //Mark button as inited to prevent double initialization
                    placeholder.attr(settings.attrs.INITED, "true");
                });

                // Autoplaing on Beauty Edit page => URL has #play-video
                if (ori.device.activeBreakpoint.index >= SIZE_3_INDEX &&
                     location.hash.search(settings.other.AUTO_PLAY_HASH_MARK) > -1) {
                    $(settings.selectors.BUTTONS_OPEN_VIDEO).click();
                }

                //Show video embedded or in popup based on the current breakpoint
                var $wVideoContainer = $(settings.selectors.W_VIDEO_CONTAINER),
                    $wVideoContainerChildrenClone = $wVideoContainer.children().clone(),
                    cboxElementClass = "cboxElement",
                    handleVideoOnResize = function () {
                        if (!ori.device.isFifthBreakpoint) {
                            $wVideoContainer.children().remove();
                            placeholders.removeClass(cboxElementClass);
                        } else {
                            if (!$wVideoContainer.children().length)
                                $wVideoContainer.append($wVideoContainerChildrenClone);
                            placeholders.addClass(cboxElementClass);
                        }
                    };
                if (ori.device && $wVideoContainer.length > 0) {
                    handleVideoOnResize();
                    ori.device.bindResize(handleVideoOnResize, self.ns);
                }
            }
        }
    });
    //Setup self
    self = ori.ytplayer;
}());